<template>


  <div style="background-color:#eeee">

    <div class="search_component" row wrap style="height:300px">

    </div>


    <div style="background-color:#fff">
      <v-container>
        <br>

        <v-layout row wrap>





          <v-flex xs12 md3 sm3>
            <p class="lap_ser">البحث</p>
            <autocomplete :search="filter" placeholder="البحث" @submit="search_select"
              @change="search_fun(search.sub_cat_id,provinces_id,search.text_titel)"></autocomplete>
          </v-flex>


          <v-flex xs12 md3 sm3 pr-4>
            <p class="lap_ser">المحافظه</p>
            <treeselect :options="options_provinces" :flat="true" v-model="provinces_id" :auto-load-root-options="false"
              :multiple="true" placeholder="اختر المحافظة" height="30px" required />
          </v-flex>


          <v-flex xs12 md3 sm3 pr-4>
            <p class="lap_ser">القسم</p>
            <treeselect :multiple="true" search-nested :options="options" :disable-branch-nodes="true"
              v-model="search.sub_cat_id" placeholder=" اختار القسم" />
          </v-flex>




          <v-flex xs12 md3 sm3 pt-2>
            <br>
            <v-btn class="ma-2" tile outlined color="#ff6600" width="200" height="50" pt-4
              @click="search_fun(search.sub_cat_id,provinces_id,search.text_titel)">
              <v-icon right>fas fa-search</v-icon> البحث
            </v-btn>
          </v-flex>

        </v-layout>



      </v-container>

    </div>




    <div>
      <v-container fluid="">
        <v-row justify="center">
          <!-- partion one in the right for category -->
          <v-flex xs12 xl2 md2 sm3 pa-3 v-show="!loading">
            <filterCard :categores="categores" :options_provinces="options_provinces" :provinces="provinces"
              @inputData="setCategory" @getlocation="setlocation"></filterCard>

          </v-flex>
          <v-flex xs12 xl2 md2 sm3 pa-3 v-show="loading">
            <v-skeleton-loader ref="skeleton" type="article" class="mx-auto"></v-skeleton-loader>
            <v-skeleton-loader v-for="a in 6" ref="skeleton" type="list-item-two-line" class="mx-auto" :key="a">
            </v-skeleton-loader>

          </v-flex>
          <!-- partiton tow item details in center -->
          <v-flex xs12 xl8 md9 sm9 pa-3 v-if="!loading">



            <cardItem :items="items">
            </cardItem>



            <v-layout row wrap>
              <v-row justify="center" pa-5 style="margin-top:20px">

                <label class="mybooking-title" v-show="error_msg">
                  <v-icon color="warning"> fas fa-exclamation-triangle</v-icon>
                  لا توجد حجوزات
                </label>
                <v-btn color="gray" @click="getMore_page" v-show="getmore" text="">
                  <v-icon size="40">fas fa-chevron-circle-down</v-icon>
                </v-btn>

              </v-row>
            </v-layout>

          </v-flex>
          <v-flex v-else xs12 xl8 md9 sm9 pa-3>
            <v-flex xs12 sm12 md12 style="width:100%;padding-bottom:2%" v-for="a in 3" :key="a">
              <v-card>
                <v-skeleton-loader ref="skeleton" type="article" class="mx-auto"></v-skeleton-loader>
                <v-skeleton-loader ref="skeleton" type="list-item-two-line" class="mx-auto"></v-skeleton-loader>
              </v-card>
            </v-flex>


          </v-flex>



        </v-row>



        <v-layout>


        </v-layout>
      </v-container>
    </div>
  </div>


</template>
<script>
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  //import home from './sub_components/home1'
  //import schedual from './sub_components/schedual'
 
  import cardItem from './sub_components/items_card'
  import filterCard from './sub_components/filter'


  const axios = require('axios');

  export default {
    data() {


    

      return {
        search: [{
          sub_cat_id: null,
        }],
        id: 0,
        govs: [{
          id: '1',
          name: 'Bagdad',
        }],
        sub_cat: [{
          id: '',
          lable: ''
        }],
        
        options: [],
        optionss: null,
        options_provinces: [],
        provinces_id: '',
        items: [],
        getmore: false,
        loading: false,
        value: [],
        provinces: [],
        current_page: 0,
        last_page: 0,
        //options: [],
        xx: null,
        items_serch_titel: [],
        error_msg: false,
        selected_sub_cat: [],
        numof_subcat_selected: -1,
        rating: 5,
        
        
      }
    },
    mounted() {


      this.getitems();
      this.getProvinces();
      this.getCategory();
      this.Get_All_items_title();

    },
    methods: {
      setlocation() {

      },
      setCategory(sub_Cat_ides, provinces_id) {
        provinces_id,

        this.selected_sub_cat = sub_Cat_ides;
        this.search.sub_cat_id = sub_Cat_ides;



        if (this.selected_sub_cat.length != this.numof_subcat_selected && this.selected_sub_cat.length != 0) {

          this.numof_subcat_selected = this.selected_sub_cat.length;
          this.search.sub_cat_id = this.selected_sub_cat;
          this.search.text_titel = null;



          this.search_fun(this.selected_sub_cat, this.provinces_id, null)
        } else if (this.selected_sub_cat.length == 0 && this.numof_subcat_selected != -1) {

          this.numof_subcat_selected = -1;

          this.search_fun_start();


        }
      },
      getCategory() {

        var url = this.Url + "/getInfo/Category";
        axios.get(url).then(response => {
          this.categores = response.data.Data;
          var i = 0;
          for (i = 0; i <= this.categores.length; i++) {

            this.categores[i].id = i + 100;


            this.sub_cat = this.categores[i].sub_category.map(item => {
              return {
                id: item.id,
                label: item.name
              };
            });
            var j = 0;
            for (j = 0; j <= this.categores[i].sub_category.length; j++) {
              //  this.sub_category[j].id;
              // this.sub_cat[j].id=this.categores[j].id+'_'+this.sub_cat[j].id;

              //                 if(this.sub_cat[j].id)
              //                 {
              // alert(this.sub_cat[j].label);
              //                 }

            }



            //Push Categories in TreeSelect
            this.options.push({
                id: 'x' + this.categores[i].id,
                label: this.categores[i].name,
                children: this.sub_cat

                //    this.sub_cat


              }

            );



          }
         




        });


      },
      getitems() {

        var url = this.Url + "/getLast?page=" + (this.current_page + 1);
        this.loading = true;
        this.error_msg = false;
        this.getmore = false;
        axios.get(url).then(response => {
          this.items = response.data.Data.data;

          this.current_page = response.data.Data.current_page;
          this.last_page = response.data.Data.last_page;
          //hidden getmore button if there is no more 
          if (this.current_page == this.last_page) {
            this.getmore = false;
          } else {
            this.getmore = true
          }
          if (this.items.length < 1) {
            this.error_msg = true
          }
        }).catch(
          this.$swal('خطاء', "خطاء بالاتصال", 'error')
        ).finally(
          this.loading = false
        );
      },



      getitemsByCat_id() {
        var url = this.Url + "/getByCatId/" + this.id;
        axios.get(url).then(response => {
          this.items = response.data.Data[0].sub_category;
         


        }).catch(
          this.$swal('خطاء', "خطاء بالاتصال", 'error')
        );
      },

      Get_All_items_title() {

        var url = this.Url + "/getLast?page=1";
        axios.get(url).then(response => {
          var items_serch = response.data.Data.data;

          var i = 0;

          for (i = 0; i < items_serch.length; i++) {

            this.items_serch_titel.push(items_serch[i].name);

          }


        }).catch(

        );


      },
      filter(input) {

        if (input.length < 1) {
          this.search.text_titel = null;
          return []
        }
        return this.items_serch_titel.filter(item => {

          this.search.text_titel = input;
          return item.toLowerCase()
            .startsWith(input.toLowerCase())
        })
      },
      search_select(input_search) {



        this.search_fun(this.search.sub_cat_id, this.provinces_id, input_search);
        // this.$router.push({name: 'search', params: {cat_id:this.search.sub_cat_id+'',
        //     gov_id:'1',
        //     tit: input_search}})

      },


      search_fun(sub_Cat_id, provinces_id, title) {

        this.xx = title;
        this.getmore = false;


        this.loading = true;
        if (title == undefined) {
         
          title = null;

        } else {
         // title = title;
        }




        if (sub_Cat_id == undefined) {
          sub_Cat_id = undefined;
        }
         else if (sub_Cat_id.length == 0) {
          sub_Cat_id = undefined
        } else {
         // sub_Cat_id = sub_Cat_id;
        }





        if (provinces_id == undefined) {
          provinces_id = null;
        }
        if (isNaN(this.xx)) {
          this.xx = null;

        }

        // alert(this.search.sub_cat_id.length);

        axios({
            method: 'get',
            url: this.Url + "/search/" + sub_Cat_id + "/" + provinces_id + "/" + title + ""

          })
          .then(response => {
            this.error_msg = false;
            this.items = response.data.Data.data;
            this.xx = null;
            if (this.items.length < 1) {
              this.error_msg = true
            }

          })
          .catch(
            this.error_msg = true
          ).finally(
            this.loading = false
          );

      },
      getProvinces() {


        var url = this.Url + "/getInfo/Province/1";
        axios.get(url).then(response => {
          this.provinces = response.data.Data;
          var i = 0;
          for (i = 0; i <= this.provinces.length; i++) {

            this.options_provinces.push({
              id: this.provinces[i].id,
              label: this.provinces[i].province_name,
            });


          }


        });

      },


      getdays(date) {

        var days = ["السبت", "الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة"];
        if (date.every_day == 0) {
          if (days[date.from_day] == days[date.to_day]) {
            return days[date.from_day]
          } else {
            return days[date.from_day] + " - " + days[date.to_day];
          }


        } else {
          return "كل الايام";
        }
      },
      getMore_page() {
        this.loading = true;
        if (this.current_page == this.last_page) {
          this.getmore = false;
          this.loading = false;
        } else {


          var url = this.Url + "/getLast?page=" + (this.current_page + 1);
          axios.get(url).then(response => {

            this.items.push.apply(this.items, response.data.Data.data);
            this.current_page = response.data.Data.current_page;
            this.last_page = response.data.Data.last_page;
            this.loading = false;


            if (this.current_page == this.last_page) {
              this.getmore = false;
            } else {
              this.getmore = true
            }




          }).catch(
            this.$swal('خطاء', "خطاء بالاتصال", 'error'),
            this.loading = false,
          );




        }

      }
    },



    components: {
      Treeselect,
      cardItem,
      filterCard

    }
  };
</script>